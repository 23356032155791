<template>
  <div class="banner">
    <img class="bg" src="../../assets/images/banner_bg.png"/>
    <p class="text"></p>
    <div class="center">
      <el-row>
        <el-col :span="10">
          <el-card class="search-card">
            <el-form :model="searchForm" label-width="120px">
              <el-form-item label="位置区域">
                <el-cascader
                    ref="cascader"
                    v-model="searchForm.region"
                    :options="searchForm.cascaderData"
                    :props="{expandTrigger: 'hover', value: 'id',label: 'name',children: 'cities', checkStrictly: true}"
                    clearable
                    separator=" - "
                    @change="handleChange"
                    @active-item-change="handleItemChange">
                </el-cascader>
              </el-form-item>
              <el-form-item class="type-item" label="登记单元类型">

                <el-row class="types-row type-zylx">
                  <span :class="{'checked': item.isChecked}"  v-for="(item, index) of searchForm.types.data" :key="index" @click="handleCheck(item, searchForm.types)">{{ item.mc }}</span>
                </el-row>
              </el-form-item>
              <el-form-item class="type-item" label="登记成果">
                <el-row class="types-row">
				  <el-col v-for="(item, index) of djdydata1" :key="index" :span="8">	
                    <span  @click="detail(item.id)">{{ item.djdymc }}</span>
                  </el-col>
				  <el-popover
                      placement="right"
                      title=""
                      trigger="hover"
                      :disabled="djdydata2.length === 0"
                      width="450">
                    <div class="moreBox">
                      <el-row :gutter="10">
                        <el-col v-for="(item, index) of djdydata2" :key="index" :span="8">
                          <span style="cursor: pointer;" @click="detail(item.id)">{{ item.djdymc }}</span>
                        </el-col>
                      </el-row>
                    </div>					
                     <span slot="reference" class="more">更多>></span>					
                  </el-popover>
                </el-row>
              </el-form-item>
              <el-row>
                <el-button class="search-btn" type="primary" @click="searchData">搜索</el-button>
              </el-row>
            </el-form>
          </el-card>
        </el-col>
        <el-col :span="14">
          <div class="carousel">
            <el-carousel height="384px">
              <el-carousel-item v-for="lamp of lamps" :key="lamp.id">
                <div class="img-box">
                  <img :src="lamp.tpdz" style="height: 384px;width: 100%;"
                       @click="navigateTo(lamp.qtlj,lamp.lx,lamp.zrzydyId)">
                  <p>
                    <span>{{ lamp.bt }}</span>
                  </p>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import {getEnumList, getEnumListByXzq, getSearchEnumList, getSwiperListByClass} from "@/api/homeService";
import {getDyInfoBySearch} from "@/api/dyInfoService";

export default {
  data() {
    return {
      lamps: [],
      isChecked: true,
      searchForm: {
        cascaderData: [], // 地区集合
        types: {
          title: '资源类型',
          data: []
        },
        areas: {
          title: '自然保护地',
          data: []
        },
        jbs: {
          title: '级别',
          data: [{
            mc: '国家级',
            isChecked: false,
            mcdm: 'gjj'
          }, {
            mc: '省级',
            isChecked: false,
            mcdm: 'sj'
          }]
        },
      },
      jb: '',
      curr: {},
      /* 临时数据,查看效果 */
      zylx: [{
        mc: '海域',
        isChecked: false,
      }, {
        mc: '无居民海岛',
        isChecked: false,
      }, {
        mc: '自然保护地',
        isChecked: false,
      }, {
        mc: '水流',
        isChecked: false,
      }, {
        mc: '森林',
        isChecked: false,
      }, {
        mc: '省级',
        isChecked: false,
      }, {
        mc: '森林',
        isChecked: false,
      }, {
        mc: '省级',
        isChecked: false,
      }, {
        mc: '国务院确定的重点国有林场',
        isChecked: false,
      },
        {
          mc: '探明储量的矿产资源',
          isChecked: false,
        }
      ],
      djdy: [
        {
          mc: '钱江源国家公园',
          isChecked: false,
        }, {
          mc: '三江源国家公园',
          isChecked: false,
        }, {
          mc: '东北虎豹国家公园',
          isChecked: false,
        }, {
          mc: '武夷山国家公园',
          isChecked: false,
        }, {
          mc: '大熊猫国家公园',
          isChecked: false,
        }, {
          mc: '祁连山国家公园',
          isChecked: false,
        }, {
          mc: '黑龙江平顶山国家自然保护区',
          isChecked: false,
        }, {
          mc: '北京松山国家 自然保护区',
          isChecked: false,
        }
      ],
      djdydata1: [],
      djdydata2: [],
    }
  },
  mounted() {
    this.SwiperListInit();
    this.SearchEnumListInit();
    this.getNodes();
    this.getDjdyByDjsj();
  },
  methods: {

    //获取顶层登记单元
    getDjdyByDjsj() {
      const search = {"lx": "new", "start": 0, "size": 10, "order": "fbsj", "sort": "desc"}
      getDyInfoBySearch(search).then(res => {
        if (res.code === "0") {
          const data = res.data
          for (let i = 0; i < data.length; i++) {
            if (i < 6) {
              this.djdydata1.push(data[i])
            } else {
              this.djdydata2.push(data[i])
            }
          }
        }
      })
    },
    /**
     * 根据当前单元号跳转
     * @param currentId
     */
    detail(currentId) {
      this.$router.push({
        name: 'infoDetail',
        query: {
          zrzyDyId: currentId
        }
      })
    },

    //obj: areas:{title: '自然保护地',data:[]}  curr:当前data下面的具体项
    handleCheck(curr, obj) {
      if (obj.title == '资源类型' || obj.title == '自然保护地') {
        this.searchForm.areas.data.map(item => {
          item.isChecked = false
        })
        this.searchForm.types.data.map(item => {
          item.isChecked = false
        })
        this.curr = curr;
      } else {
        this.searchForm.jbs.data.map(item => {
          item.isChecked = false
        })
        this.jb = curr.mcdm;
      }
      curr.isChecked = !curr.isChecked

    },

    handleChange() {
      this.$refs.cascader.dropDownVisible = false;
    },

    /**
     * 获取下一级操作
     * */
    handleItemChange(val) {
      this.getNodes(val)
    },
    /**
     * 获取地区枚举
     * @param fid
     */
    async getDqMj(fid) {
      const mj = {
        'fid': fid,
        'zudm': 'xzqEnum'
      }
      const result = await getEnumList(mj)
      if (result.code === '0') {
        return result.data
      }
    },

    async getEnumListByXzq(fid) {
      const mj = {
        'fid': fid,
        'zudm': 'xzqEnum'
      }
      const result = await getEnumListByXzq(mj)
      if (result.code === '0') {
        return result.data
      }
    },
    /**
     * 获取下一级区县
     * @param val
     */
    getNodes(val) {
      let idArea
      let sizeArea
      if (!val) {
        idArea = null
        sizeArea = 0
      } else if (val.length === 1) {
        idArea = val[0]
        sizeArea = val.length // 3:一级 4:二级 6:三级
      } else if (val.length === 2) {
        idArea = val[1]
        sizeArea = val.length // 3:一级 4:二级 6:三级
      }
      if (sizeArea === 0) { // 初始化 加载一级 省
        this.getDqMj('xzq0').then(response => {
          if (response) {
            this.searchForm.cascaderData = response.map((value, i) => {
              return {
                id: value.mcdm,
                name: value.mc,
                cities: []
              }
            })
          }
        })
      } else if (sizeArea === 1) { // 点击一级 加载二级 市
        this.getEnumListByXzq( idArea).then(response => {
          if (response) {
            this.searchForm.cascaderData.map((value, i) => {
              if (value.id === val[0]) {
                if (!value.cities.length) {
                  value.cities = response.map((value, i) => {
                    return {
                      id: value.mcdm,
                      name: value.mc,
                      cities: []
                    }
                  })
                }
              }
            })
          }
        })
      } else if (sizeArea === 2) { // 点击二级 加载三级 区
        this.getEnumListByXzq( idArea).then(response => {
          if (response) {
            this.searchForm.cascaderData.map((value, i) => {
              if (value.id === val[0]) {
                value.cities.map((value, i) => {
                  if (value.id === val[1]) {
                    if (!value.cities.length) {
                      value.cities = response.map((value, i) => {
                        return {
                          id: value.mcdm,
                          name: value.mc
                        }
                      })
                    }
                  }
                })
              }
            })
          }
        })
      }
    },

    /**
     * 首页轮播图初始化
     */
    SwiperListInit() {
      getSwiperListByClass({}).then(response => {
        this.lamps = response.data
      })
    },
    /**
     * 首页搜索页面条件初始化
     * 不用传参
     * @constructor
     */
    SearchEnumListInit() {
      getSearchEnumList().then(res => {
        if (res.code == "0") {
          let zylx = res.data.zylx;
          for (let i = 0; i < zylx.length; i++) {
            zylx[i].isChecked = false;
          }
          this.searchForm.types.data = zylx;
          let zrbhq = res.data.zrbhq;
          for (let i = 0; i < zrbhq.length; i++) {
            zrbhq[i].isChecked = false;
          }
          this.searchForm.areas.data = zrbhq;
        }
      })
    },
    /**
     *  首页轮播图判断类型后页面跳转
     *  @param qtlj:轮播图跳转的地址 lx:1为站内地址，2为站外地址
     */
    navigateTo(qtlj, lx, zrzydyId) {
      if (lx == 1) {  //站内
        const urls = qtlj.split('/');
        this.$router.push({
          name: urls[0],
          query: {
            zrzyDyId: urls[1],  //根据自然资源单元号zrzydyId
            id: urls[1]  //根据自然资源单元号zrzydyId
          }
        })
      } else if (lx == 2) {  //站外
        window.location.href = qtlj  //根据站外路径直接跳转
      }
    },
    searchData() {
      let dq = null;
      if (this.searchForm.region !== undefined && this.searchForm.region.length > 0) {
        dq = this.searchForm.region[this.searchForm.region.length - 1]
      }
      this.$router.push({
        name: 'info',
        query: {
          dq: dq,
          zylx: this.curr.mcdm,
          jb: this.jb
        }
      })
    }
  }
}
</script>
<style lang="scss">
.banner {
  position: relative;

  .text {
    position: absolute;
    width: 100%;
    height: 120px;
    top: 0;
    background: url(../../assets/images/banner_text.png) center no-repeat;
  }

  .bg {
    width: 100%;
    height: 546px;
    position: relative;
    top: 1px;
    z-index: -1;
  }

  .center {
    width: 1200px;
    position: absolute;
    left: 50%;
    top: 120px;
    margin-left: -600px;

    .search-card {
      border-radius: 0;
      height: 398px;
      .el-card__body{
        padding:16px 20px;
      }
      .el-form-item {
        margin-bottom: 8px;
        border-bottom: 1px dashed #ccc;
        padding-bottom: 0px;
        .types-row {
		  .el-col-8{
			min-height:46px;
			span:hover{
				color: #039BE5;
				background-color: #f2f2f2;  
			}
		  }	
          span {
            float: left;
            cursor: pointer;
            color: #808080;
            font-size: 14px;
            line-height: 16px;
            padding: 7px 6px;
            // margin-bottom: 5px;
            max-width: 110px;
            text-align: center;
          }

          span.checked {
            color: #039BE5;
            background-color: #f2f2f2;
          }
          
          span.more {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #0080FF;
            line-height: 16px;
            padding: 0px 0px;
			position: relative;
			top: -5px;
          }
          
        }
        .type-zylx{
          display: flex;
          flex-wrap: wrap;
			span{
				margin-bottom: 5px;
			}
			span:hover{
				color: #039BE5;
				background-color: #f2f2f2;  
			}
		} 
        .el-cascader {
          display: block;
        }
      }

      .el-form-item:first-child {
        padding-bottom: 13px;
      }

      .el-form-item__label {
        font-size: 18px;
        color: #333;
        text-align: left;
      }

      .type-item {
        /*height: 78px;*/
      }

      .search-btn {
        width: 100%;
        font-size: 24px;
        padding: 8px 20px;
        border-radius: 0;
      }
    }

    .carousel {
      margin-left: 30px;
      border: 8px solid #fff;

      .el-carousel__item {
        .img-box {
          position: relative;

          img {
            width: 100%;
            height: 100%;
            cursor: pointer
          }

          p {
            position: absolute;
            line-height: 30px;
            color: #fff;
            background-color: #015293;
            width: 100%;
            bottom: 0;

            span {
              font-size: 16px;
              padding: 0 20px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
  }
}

.el-cascader__dropdown {
  .el-cascader-node {
    .el-radio {
      position: absolute;
      height: 34px;
      left: 20px;
      right: 20px;
      opacity: 0;
    }

    .el-cascader-node__label {
      padding-left: 0;
    }
  }
}

.moreBox {
  .el-col-8{
	  min-height: 42px;
	  display: flex;
	  align-items: center;
  }
   span{
    cursor: pointer;
    color: #808080;
    font-size: 14px;
    line-height: 16px;
    padding: 5px 5px;
    max-width: 114px;
    text-align: center;
	display: flex;
	justify-content: center;
  }

  span.checked {
    color: #039BE5;
    background-color: #f2f2f2;
  }
  span:hover{
	color: #039BE5;
	background-color: #f2f2f2;  
  }
}
</style>
