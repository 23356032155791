<template>
    <div>
        <template v-for="item in menuList">
            <el-submenu v-if="item.childrens !== undefined && item.childrens.length > 0 && (item.qybz === '1' || item.qybz === '3')" popper-class="pub-pop" :index="item.pctzlj">
                <template slot="title">{{ item.mc }}</template>
                <multi-level-catalogue :menu-list="item.childrens"/>
            </el-submenu>
            <el-menu-item @click="popup(item)" v-if="item.childrens !== undefined && item.childrens.length === 0 && (item.qybz === '1' || item.qybz === '3')" :index="item.pctzlj">   <!-- 没有子目录的一级菜单，投诉/建议 -->
                <span slot="title">{{ item.mc }}</span>
            </el-menu-item>
            <el-menu-item v-if="item.childrens === undefined" class="search">
                <el-input @keyup.enter.native="searchKey" placeholder="请输入内容" v-model="search">
                    <i slot="suffix" class="el-input__icon el-icon-search" @click="searchKey"></i>
                </el-input>
            </el-menu-item>
        </template>
    </div>
</template>

<script>
    export default {
        name: "multiLevelCatalogue",
        props: ['menuList'],
        data() {
            return {
                search: '',
                tempTitle: ''      //记录弹窗的组件名称，用于弹窗的名称和对应的组件调用
            }
        },
        methods: {
            /**
             * 搜索页跳转
             */
            searchKey() {
                this.$router.push({
                    path: '/search/' + this.search
                })
            },
            /**
             * 根据LX区分站内、站外和弹窗
             * @param item
             */
            popup(item) {
                if(item.lx == '1'){
                    this.$router.push(item.pctzlj)
                }else if(item.lx == '2'){
                    window.open(item.pctzlj)
                }else if(item.lx == '3') {
                    let params = {
                        title: item,
                        flag: true
                    }
                    this.$emit('toParent', params)
                }
            }
        }
    }
</script>

<style lang="scss">
    .el-menu--horizontal>div>.el-submenu {
        float: left;
    }
    /* 一级菜单的样式 */
    .el-menu--horizontal>div>.el-menu-item {
        float: left;
        height: 60px;
        line-height: 60px;
        margin: 0;
        border-bottom: 2px solid transparent;
        color: #909399;
    }
    /* 解决下图1 下拉三角图标 */
    .el-menu--horizontal>div>.el-submenu .el-submenu__icon-arrow {
        position: static;
        vertical-align: middle;
        margin-left: 8px;
        margin-top: -3px;
    }
    /* 解决下图2 无下拉菜单时 不对齐问题 */
    .el-menu--horizontal>div>.el-submenu .el-submenu__title {
        height: 60px;
        line-height: 60px;
        border-bottom: 2px solid transparent;
        color: #909399;
    }
</style>
