<template>
  <div class="header">
    <div class="top">
      <div class="center">
        <el-row>
          <el-col :offset="20" :span="4">
            <p v-if="this.$store.state.userInfo!==null" class="login-p">
              <a class="line">|</a>
              <span :style="avatar" @click="toProfile">{{ decodeURIComponent(this.$store.state.userInfo.user_name) }}</span>
              <a class="line">|</a>
              <span @click="loginCancel">注销</span>
            </p>
            <p v-else class="login-p">
              <span class="signin" @click="signIn">登录</span>
              <a class="line">|</a>
              <span class="signup" @click="signUp">注册</span>
            </p>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="logo-bg">
      <el-row>
        <el-col :span="15">
          <div class="logo">
            <p class="cn">自然资源统一确权登记</p>
<!--            <p class="en">Unified Confirmation and Registration of Natural Resources Rights</p>-->
          </div>
        </el-col>
        <el-col :span="9">
          <div class="bg">
            <img src="../../assets/images/logo-bg.png"/>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="menu">
      <el-menu
          :default-active="activeIndex"
          :unique-opened="true"
          background-color="#039BE5"
          mode="horizontal"
          text-color="#fff"
          @select="handleSelect">
        <multi-level-catalogue :menu-list="navList" @toParent="fromChild"/>
      </el-menu>
    </div>

    <el-dialog
        :title="tempTitle"
        :visible.sync="centerDialogVisible"
        center
        custom-class="sugg-dialog"
        destroy-on-close
        top="10vh"
        width="800px">
      <component :is="componentName" @closeVisible="closeDialog"></component>
    </el-dialog>
  </div>
</template>
<script>
import storage from '@/utils/storage'
import {getAllTSyDhlDO} from "../../api/homeService";
import Tsjy from "../../components/tsjy";
import multiLevelCatalogue from "../multiLevelCatalogue";
import {tsjyLoginState} from "../../api/personService";

export default {
  components: {
    Tsjy, multiLevelCatalogue
  },
  data() {
    return {
      activeIndex: '/index',
      navList: [],
      search: '',
      avatar: {
        paddingLeft: "32px",
        backgroundImage: '',
        backgroundSize: '24px 24px',
      },
      centerDialogVisible: false,
      componentName: '',
      tempTitle: ''
    }
  },
  watch: {
    $route: {
      handler() {
        this.activeIndex = '/' + this.$route.path.split('/')[1];
        //深度监听，监听地址的变化
      },
      deep: true,
    }
  },
  methods: {
    fromChild(data) {
      this.tempTitle = data.title.mc
      this.componentName = data.title.pctzlj
      tsjyLoginState().then(res => {
        // this.centerDialogVisible = true;
        this.$router.push({
          path: "/tsjycon",
        });
      })
    },
    //关闭弹窗
    closeDialog(data) {
      this.centerDialogVisible = data
    },
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
    signIn() {
      this.$router.push({
        path: '/login'
      })
    },
    signUp() {
      if (this.$route.name != 'signup') {
        this.$router.push({
          path: '/signup'
        })
      }
    },
    toProfile() {
      this.$router.push({
        path: '/profile'
      })
    },
    loginCancel() {
      storage.remove('user');
      this.$router.push({
        path: '/login'
      })
    }
  },
  mounted() {
    /**
     * 动态获取后台首页菜单
     */
    getAllTSyDhlDO().then(response => {
      const data = response.data;
      const list = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].qybz === '1' || data[i].qybz === '3') {
          if (data[i].childrens.length > 0) {
            let count = 0;
            let onlyData = {};
            //判断有效的子菜单数量
            for (let y = 0; y < data[i].childrens.length; y++) {
              if (data[i].childrens[y].qybz === '1' || data[i].childrens[y].qybz === '3') {
                count++;
                onlyData = data[i].childrens[y]
              }
            }
            //如果有效的子菜单只有1个 则菜单为子菜单
            if (count === 1) {
              list.push(onlyData)
            } else {
              list.push(data[i]);
            }
          } else {
            list.push(data[i]);
          }


        }
      }
      this.navList = list;
      let searchObj = {search: ''}
      this.navList.push(searchObj)
    })
  },
  created() {
    // 根据路由状态，设置导航active状态
    this.activeIndex = '/' + this.$route.path.split('/')[1];
    const user = storage.get('user');
    this.$store.commit("addUser", {user});
    if (user != null) {
      // 根据localStorage检验是否登录
      this.avatar.backgroundImage = 'url(' + user.headportrait + ')'
    }
    console.log(this.$store.state.userInfo)
  }
}
</script>
<style lang="scss">
.sugg-dialog {
  .explain {
    text-align: justify;

    P {
      font-size: 12px;
      color: rgba(102, 102, 102, 1);
      line-height: 18px;

    }
  }
}

.header {
  .top {
    background-color: #f5f5f5;

    .login-p {
      text-align: right;
      line-height: 32px;

      span {
        cursor: pointer;
        display: inline-block;
        line-height: 20px;
        margin: 0 10px;
        background: no-repeat left center;
      }

      span:hover {
        color: #0180B7;
      }

      .signin {
        padding-left: 21px;
        background-image: url(../../assets/images/icon_user.png)
      }

      .signup {
        margin-right: 0;
      }


      a {
        display: inline-block;
        color: #ccc;
        font-size: 14px;
      }
    }
  }

  .logo-bg {
    width: 1200px;
    margin: 0 auto;
    // padding:25px 0;

    .logo {
      /*width: 490px;*/
      cursor: pointer;
      color: #0180B7;
      padding: 23px 0 15px 0px;
      text-align: left;
      /*background: url(../../assets/images/logo.png) left center no-repeat;*/
      .cn {
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 4.5px;
        padding:0px 0px 0px 0px;
      }
      .en {
        font-size: 12px;
        margin-top: 7px;
      }
    }

    .bg {
      text-align: right;
      padding-top: 17px;
    }
  }

  .menu {
    height: 48px;
    background-color: #039BE5;

    .el-menu.el-menu--horizontal {
      border-bottom: none;
    }

    .el-menu--horizontal {
      width: 1200px;
      margin: 0 auto;

      .el-menu-item, .el-submenu .el-submenu__title {
        height: 43px;
        line-height: 43px;
        padding: 0;
        padding-top: 2px;
        font-size: 16px;
        margin-right: 42px;
      }

      .el-menu-item:hover {
        background-color: rgb(3, 155, 229);
      }

      .el-menu-item:hover, .el-submenu .el-submenu__title:hover {
        background-color: rgb(3, 155, 229) !important;
        border-bottom-color: #fff !important;
      }

      .el-submenu .el-submenu__title {
        i {
          color: #fff;
        }
      }

      .is-active .el-submenu__title {
        color: #fff;
      }


      .el-menu-item * {
        vertical-align: inherit;
      }

      .el-menu-item:last-child {
        float: right;
        margin-right: 0;

        .el-input {
          display: block;

          .el-input__inner {
            border-radius: 0;
            height: 32px;
            line-height: 32px;
            border-radius: 4px;
          }

          .el-input__suffix {
            top: 2px;

            .el-icon-search {
              color: #039BE5;
              font-size: 20px;
            }
          }

        }
      }

      .el-menu-item:last-child {
        height: 48px;
        line-height: 48px;
        padding-top: 0;
      }

      .el-menu-item:last-child:hover {
        background-color: #039BE5 !important;
        border-bottom-color: transparent !important;
      }
    }

    .el-menu--horizontal > div > .el-menu-item.is-active {
      color: #fff;
      border-bottom-color: #fff;
    }

    .el-menu--horizontal > div > .el-submenu.is-active .el-submenu__title {
      border-bottom-color: #fff;
    }

    .el-menu--horizontal > div > .el-menu-item.search {
      border-bottom-color: #039BE5
    }

  }
}

.pub-pop {
  width: 150px;

  .el-menu {
    min-width: 150px !important;
    background-color: #fff !important;

    .el-menu-item {
      color: #999 !important;
      background-color: #fff !important;
    }

    .el-menu-item:hover, .el-menu-item.is-active {
      background-color: rgb(3, 155, 229) !important;
      color: #fff !important;
    }

    .el-submenu .el-menu-item {
      min-width: auto;
    }

    /*.el-submenu.is-active.is-opened {*/

    /*    .el-submenu__title {*/
    /*        color: #fff;*/
    /*    }*/
    /*}*/

  }
}
</style>
