<template>
    <!-- 投诉建议窗口 -->
    <div>
        <div class="explain-form">
            <el-form :model="ruleForm" :rules="rules" class="demo-ruleForm" label-width="100px" ref="ruleForm">
                <el-form-item class="mar-bot" label="单元名称：" v-if="ruleForm.dymc!=''&&ruleForm.dymc!=null">
                    <el-input :disabled="ruleForm.disabled" placeholder="" v-model="ruleForm.dymc"></el-input>
                </el-form-item>

                <el-form-item label="问题类型：" prop="wtlx">
                    <el-radio-group v-if="!ruleForm.disabled" v-model="ruleForm.wtlx">
                        <el-radio label="1">投诉</el-radio>
                        <el-radio label="2">建议</el-radio>
                    </el-radio-group>
                    <el-input :disabled="ruleForm.disabled" placeholder="" v-if="ruleForm.disabled"
                              v-model="ruleForm.wtlx"></el-input>
                </el-form-item>
                <el-form-item label="工作单位：">
                    <el-input :disabled="ruleForm.disabled" placeholder="" v-model="ruleForm.gzdw"></el-input>
                </el-form-item>
                <el-form-item class="mar-bot" label="问题标题：" prop="wtbt">
                    <el-input :disabled="ruleForm.disabled" placeholder="" v-model="ruleForm.wtbt"></el-input>
                </el-form-item>
                <el-form-item class="mar-bot" label="问题描述：" prop="wtms">
                    <el-input :disabled="ruleForm.disabled" type="textarea" v-model="ruleForm.wtms"></el-input>
                </el-form-item>
                <el-form-item label="上传附件：" v-if="!ruleForm.disabled">
                    <div class="upload-file">
                        <el-upload
                                :action="uploadAction"
                                :before-upload="beforeAvatarUpload"
                                :disabled="ruleForm.disabled"
                                :headers="headers"
                                :on-remove="handleRemove"
                                :on-success="handlePictureCardPreview"
                                list-type="picture-card">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </div>
                </el-form-item>
                <el-form-item class="mar-bot" v-if="!ruleForm.disabled">
                    <el-checkbox :disabled="ruleForm.disabled" v-model="checked"></el-checkbox>
                    <span @click="tpis=true" style="cursor:pointer;color: blue">  已阅读《提交说明》</span>
                </el-form-item>
                <el-form-item class="mar-bot" label="附件：" v-if="ruleForm.disabled&&ruleForm.fj!=null&&ruleForm.fj!=''">
                    <img :src="ruleForm.fj" style="width: 480px;height: 240px">
                </el-form-item>
                <el-form-item class="mar-bot" label="回复：" v-if="ruleForm.disabled">
                    <el-input :disabled="ruleForm.disabled" type="textarea" v-model="ruleForm.hf"></el-input>
                </el-form-item>

            </el-form>
        </div>
        <div class="dialog-footer" slot="footer" style="text-align: center" v-if="!ruleForm.disabled">
            <el-button @click="cancelRule">取 消</el-button>
            <el-button @click="saveRule" type="primary">确 定</el-button>
        </div>
        <el-dialog
                :visible.sync="tpis"
                append-to-body
                center
                custom-class="sugg-dialog"
                top="10vh"
                width="800px">
            <div class="explain">
                <p>为确保你的留言及时通过审核并尽快得到回复，请您仔细阅读以下说明：</p>
                <p>一、*为必填项，所有*项填完才能成功提交。</p>
                <p>
                    二、您的留言如涉及以下内容将不予受理，情节恶劣将向公安机关报案：1、违反国家法律法规和涉及国家机密的留言；2、应通过司法程序解决，正在进行或已完成司法程序、行政复议、劳动仲裁的留言；3、恐吓、威胁、诽谤他人或单位的留言；4、对同一问题恶意连续、重复提交的留言；5、虚假、语言模糊、问题阐述不明确的留言；6、使用侮辱性、歧视性等不文明语言的留言；7、易引起恐慌、网络群体性事件等问题的留言；8、投诉、举报或已在信访部门反映的留言。</p>

                <p>
                    四、留言提交、回复及状态查询：1、留言提交后，须经审核通过才会再网站页面显示；2、留言审核与分拨时间为每个工作日的上午8时30分至下午17时30分，周末双休日及法定节假日将不审核与分拨；3、留言问题将尽快核实答复，对于情况复杂，一时难以处理的留言，会适当延长办理与答复延期，时限最长为3个月；4、登录后可在我的中心查看您的咨询投诉情况。</p>
            </div>
            <span class="dialog-footer" slot="footer">
                <el-button @click="tpis = false" type="primary">确 定</el-button>
            </span>
        </el-dialog>


        <el-dialog
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :modal="true"
                :show-close="false"
                :visible.sync="loginDialog"
                append-to-body>
            <login-card v-on:closeLogDialog="closeLogDialog"></login-card>
        </el-dialog>

    </div>

</template>

<script>

    import urlConfig from "../utils/urlConfig";
    import LoginCard from "../views/login/loginCard";
    import storage from "../utils/storage";
    import {saveComplaintAndAdvice} from "../api/personService";

    export default {
        name: "tsjy",
        components: {LoginCard},
        data() {
            return {
                uploadAction: urlConfig.upLoadUrl + '/graph/saveGraphImage?twlx=tsyj',
                loginDialog: false,
                ruleForm: {
                    wtlx: '',
                    gzdw: '',
                    sfgk: '',
                    wtbt: '',
                    wtms: '',
                    gldyid: '',
                    fj: '',
                    dymc: "",
                    yhid: '',
                    disabled: false,
                    hf: ''
                },
                dialogVisible: false,
                checked: false,
                tpis: false,
                rules: {
                    wtlx: [
                        {required: true, message: '请选择', trigger: 'change'}
                    ],
                    wtbt: [
                        {required: true, message: '请输入问题', trigger: 'blur'}
                    ],
                    wtms: [
                        {required: true, message: '请填写', trigger: 'blur'}
                    ]
                },
                headers: {
                    'X-Gisq-Token': ''
                },
            }
        },
        mounted() {
            const user = storage.get("user")
            this.headers['X-Gisq-Token'] = "Bearer " + user.access_token
        },
        methods: {
            closeLogDialog() {
                this.loginDialog = false;
            },

            //上传附件
            handleRemove(file, fileList) {
                console.log(file, fileList);
            },
            handlePictureCardPreview(res, file) {
                this.ruleForm.fj = res.finalUrl
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG) {
                    this.$message.error('上传图片只能是 JPG/PNG/GIF 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            },
            getTs() {
                this.$refs['ruleForm'].validate(valid => {
                    if (valid) {
                        if (!this.checked) {
                            this.$message.info("请勾选 已阅读《提交说明》")
                        } else {
                            saveComplaintAndAdvice(this.ruleForm).then(res => {
                                if (res.data > 0) {
                                    this.$message.success("保存成功")
                                    this.$emit('closeVisible', false)
                                }
                            })
                        }
                    }
                })
            },
            saveRule() {
                this.getTs()
            },
            cancelRule() {
                this.$emit('closeVisible', false)
            },
            setFormData(glid) {
                this.ruleForm.gldyid = glid;
            },

            setTsData(ts) {
                this.ruleForm = ts;
            }
        }
    }
</script>

<style lang="scss">
    .explain {
        text-align: justify;

        P {
            font-size: 12px;
            color: rgba(102, 102, 102, 1);
            line-height: 18px;

        }
    }

    .explain-form {
        .explain-form-title {
            line-height: 24px;
            font-size: 18px;
            color: #303133;
            text-align: center;
            padding: 24px 0px;
        }

        .el-form-item {
            margin-bottom: 10px;
        }

        .mar-bot {
            margin-bottom: 22px;
        }

        .el-form-item__content {


            .el-radio-group {
                margin-left: 15px;
            }
        }

        .el-input__inner, .el-textarea__inner {
            /*border: 1px solid #ccc;*/
            /*background: #f6f6f6;*/
        }

        .el-textarea__inner {
            height: 100px;
        }
    }

    .upload-file {
        .upload-file-title {
            line-height: 24px;
            font-size: 18px;
            color: #303133;
            text-align: center;
            padding: 24px 0px;
        }

        .el-upload-list--picture-card .el-upload-list__item {
            width: 64px;
            height: 64px;
        }

        .el-upload--picture-card {
            width: 64px;
            height: 64px;
        }

        .el-icon-plus:before {
            position: relative;
            top: -35px;
        }
    }
</style>
