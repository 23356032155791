import request from '@/utils/request'
import urlConfig from "../utils/urlConfig";

/**
 * 获取微信小程序二维码登录
 *
 */
export function getWxQRCode(req_pam) {
    return request({
        url: urlConfig.systemUrl + '/wx/user/qrcode',
        type: 'GET',
        params: req_pam
    })
}


/**
 * 获取用户详细信息
 *
 */
export function userInfo(userId) {
    return request({
        url: urlConfig.systemUrl + '/user/userInfo/' + userId,
        method: 'GET'
    })
}


/**
 * 判断用户是否存在
 *
 */
export function isUserNameExist(userName) {
    return request({
        url: urlConfig.systemUrl + '/user/isUserNameExist/' + userName,
        method: 'GET'
    })
}


/**
 * 获取用户详细信息
 *
 */
export function updateUserInfo(userId, user) {
    return request({
        url: urlConfig.systemUrl + '/user/byIdupdateUser/' + userId,
        method: 'POST',
        data: user
    })
}


/**
 * 验证token是否有效
 * @param params
 */
export function checkToken(params) {
    return request({
        url: '/auth/auth/oauth/check_token',
        method: 'get',
        params: params
    })
}


/**
 * 注册
 * @param data
 */
export function addUser(registerKey,registerCode,data) {
    return request({
        url: urlConfig.systemUrl + '/user/add?registerKey=' + registerKey + '&registerCode=' + registerCode,
        method: 'post',
        data: data
    })
}

export function updatePassword(data) {
    return request({
        url: urlConfig.systemUrl+ '/user/updatePassword2',
        method:'PUT',
        data:data
    })
}



