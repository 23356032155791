<template>
  <div class="box">
    <div id="app" v-cloak v-if="!isIE">
      <router-view name="header"></router-view>
      <router-view name="banner"></router-view>
      <div class="content">
        <router-view></router-view>
        <template>
          <el-backtop>
            <div class="backtop">
              <i class="el-icon-arrow-up"></i>
            </div>
          </el-backtop>
        </template>
      </div>
      <vfooter></vfooter>
    </div>
    <div id="ie" v-else>
      <p>
        <span>抱歉，当前浏览器不支持，请更换浏览器</span>
      </p>
    </div>
  </div>
</template>

<script>
  import vfooter from '@/components/common/footer'
  import {getHomeSystem} from "./api/homeService";
  export default {
    name: 'App',
    components: {
      vfooter
    },
    data() {
      return {
        isIE: false
      }
    },
    methods: {
      checkIE() {
        // 判断当前浏览器类型，如是IE则提示不支持
        if(!!window.ActiveXObject || "ActiveXObject" in window){
          this.isIE = true
        }
      },
      getSystem(){
        getHomeSystem().then(res=>{
          this.$store.commit("addHomeSystem", res.data);
        })
      }
    },
    created() {
      this.checkIE();
      this.getSystem();
    }
  }
</script>

<style lang="scss">
  [v-cloak]{
      display: none;
  }
  #app .content{
    margin:0 auto;
    min-height: calc(100vh - 306px);
    .backtop{
        height: 100%;
        width: 100%;
        background-color: #f2f5f6;
        box-shadow: 0 0 6px rgba(0,0,0, .12);
        text-align: center;
        line-height: 40px;
        color:#606266;
        .el-icon-arrow-up{
          font-size:22px;
        }
      }
      .backtop:hover{
        color: #1989fa;
      }
  }
  #ie{
    p{
      font-size:20px;
      text-align:center;
      padding:30px 0
    }
  }
</style>
