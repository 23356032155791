import request from '@/utils/request'
import urlConfig from "../utils/urlConfig";


export function checkCollection(params) {
    return request({
        url: urlConfig.personUrl + '/collection/checkGraphCollection',
        method: 'GET',
        params: params
    })
}

export function getCollectionState(param) {
    return request({
        url: urlConfig.personUrl + '/collection/getCollectionState',
        method: 'GET',
        params: param
    })
}

export function getGraphByCollection() {
    return request({
        url: urlConfig.personUrl + '/collection/getGraphInfoListByCollections',
        method: 'GET',
    })
}

export function getCellsByFollow() {
    return request({
        url: urlConfig.personUrl + '/collection/getCellInfoListByFollow',
        method: 'GET',
    })
}

export function getStates(param) {
    return request({
        url: urlConfig.personUrl + '/collection/getStates',
        method: 'GET',
        params: param
    })
}

/**
 * 保持投诉建议信息
 * @param data
 */
export function saveComplaintAndAdvice(data) {
    return request({
        url: urlConfig.personUrl + '/tsjy/add',
        method: 'post',
        data: data
    })
}

/**
 * 获取投诉建议信息
 * @param data
 */
export function getComplaintAndAdvice(data) {
    return request({
        url: urlConfig.personUrl + '/tsjy/query',
        method: 'post',
        data: data
    })
}

/**
 * 为查看数据
 * @param data
 */
export function queryWck(data) {
    return request({
        url: urlConfig.personUrl + '/tsjy/queryWck',
        method: 'post',
        data: data
    })
}

/**
 * 更新查看状态
 * @param data
 */
export function updateSfck(data) {
    return request({
        url: urlConfig.personUrl + '/tsjy/update',
        method: 'post',
        data: data
    })
}
/**
 * 看是否登录
 * @param data
 */
export function tsjyLoginState() {
    return request({
        url: urlConfig.personUrl + '/tsjy/loginState',
        method: 'get',
        data: {}
    })
}

/**
 * 获取投诉意见列表
 * @param tsyj
 */
export function getHomeTsjy(tsyj) {
    return request({
        url: urlConfig.personUrl + '/tsjy/getTsjy',
        method: 'post',
        data: tsyj
    })
}

/**
 * 增加阅读量
 * @param tsjyID
 */
export function addTsjySeeNum(tsjyID){
    return request({
        url: urlConfig.personUrl + '/tsjy/addTsjySeeNum/'+tsjyID,
        method: 'get',
        data: {}
    })
}
