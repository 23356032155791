<template>
    <el-card class="box-card login-card">
        <div class="clearfix" slot="header">
            <span>用户登录</span>
        </div>
        <template>
            <el-tabs @tab-click="handleClick" v-model="activeName">
                <el-tab-pane label="微信快速登录" name="weixin" v-loading="loading">
                    <div style="text-align: center">
                        <img :src="codeImg" style="width: 160px;height: 160px" v-show="!loading">
                    </div>
                    <div class="wechat-text">
                        <p>快速安全登录</p>
                        <p>请使用微信扫二维码登录</p>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="账户密码登录" name="account">
                    <el-form :model="loginForm" :rules="rules" ref="loginForm">
                        <el-form-item prop="username">
                            <i class="el-icon-user"></i>
                            <el-input v-model="loginForm.username"></el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <i class="el-icon-lock"></i>
                            <el-input @keyup.enter.native="login()" type="password"
                                      v-model="loginForm.password"></el-input>
                        </el-form-item>
                        <el-form-item class="rem-item">
                            <el-checkbox v-model="loginForm.checked">记住密码</el-checkbox>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="login()">登录</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
        </template>
    </el-card>
</template>

<script>
    import {getWxQRCode} from "../../api/systemService";
    import aes_utils from "../../utils/aes_utils";
    import loginService from "../../api/loginService";
    import storage from "../../utils/storage";

    export default {
        name: "loginCard",
        data() {
            return {
                loading: true,
                activeName: 'weixin',
                codeImg: '',
                loginForm: {
                    username: '',
                    password: '',
                    checked: false
                },
                rules: {
                    username: [
                        {required: true, message: '请输入用户名称', trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'}
                    ]
                }
            }
        },
        methods: {
            handleClick(tab, event) {
                console.log(tab, event);
            },
            login() {
                this.$refs.loginForm.validate((valid) => {
                    console.log(valid)
                    if (valid) {
                        let result = {
                            username: this.loginForm.username,
                            password: this.loginForm.password
                        };
                        let username_password_aes = aes_utils.encrypt(JSON.stringify(result));
                        let param = {
                            encryptuserinfo: username_password_aes,
                            useValidateCode: "false"
                        };

                        //用户登录接口
                        loginService.login(param).then(user => {
                            storage.set('user', user);
                            this.$store.commit("addUser", {user});
                            // 关闭组件
                            setTimeout(() => {
                                this.$emit('closeLogDialog', true)
                            }, 1000)
                        }, error => {
                            // 成功时
                            this.$message({
                                message: '用户名或密码错误',
                                type: 'error'
                            })
                        })

                    } else {
                        // 失败时
                        return false
                    }
                })
            },
        },
        mounted() {
            this.sceneStr = "scend-" + new Date().getTime() + Math.ceil(Math.random() * 888888 + 1000000);
            getWxQRCode({"sceneStr": this.sceneStr}).then(res => {
                this.codeImg = "data:image/png;base64," + res.data;
                this.loading = false;
            });
        }
    }
</script>

<style lang="scss">
    .login-card {
        position: absolute;
        width: 460px;
        height: 400px;
        /*top: 200px;*/
        left: 50%;
        margin-left: -231px;

        .el-card__header {
            /*padding: 30px 48px 25px;;*/
            text-align: center;
            border-bottom: none;

            span {
                color: #2089FF;
                font-size: 32px;
            }
        }

        .el-card__body {
            /*padding: 0px 40px 30px;*/

            .el-tabs {
                .el-tabs__header {
                    margin-bottom: 20px;

                    .el-tabs__nav-wrap::after {
                        height: 0;
                    }

                    .el-tabs__item {
                        width: 182px;
                        font-size: 16px;
                        color: #BFBFBF;
                        text-align: center;
                    }

                    .el-tabs__item.is-active {
                        color: #2089FF;
                    }

                }

                .el-tabs__content {
                    .wechat-text {
                        margin-top: 18px;

                        p {
                            text-align: center;
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }

                    .el-form {
                        .el-form-item {
                            margin-bottom: 20px;

                            [class^=el-icon-] {
                                position: absolute;
                                z-index: 1;
                                top: 12px;
                                left: 12px;
                            }

                            .el-input__inner {
                                padding-left: 36px;
                            }

                            .el-button {
                                width: 100%;
                                padding: 15px 20px;
                                font-size: 18px;
                                color: #FFF;
                                background-color: #0A7DFF;
                                border-color: #0A7DFF;

                            }

                            .el-button:focus, .el-button:hover {
                                background-color: #66b1ff;
                                border-color: #66b1ff;
                            }
                        }

                        .rem-item {
                            margin-bottom: 24px;

                            .el-form-item__content {
                                line-height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
