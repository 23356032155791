/**
 * 登录、登出api接口管理
 */

import request from '@/utils/request';
import urlConfig from "../utils/urlConfig";

let loginService = {
    /**
     * 登陆
     */
    login(params) {
        return request({
            url: '/auth/auth/oauth/login',
            method: 'post',
            params: params
        })
    },

    /**
     * 登出
     */
    logout() {
        return request({
            url: '',
            method: 'get'
        })
    },
    /**
     * 验证码登录
     */
    userInfoBind(param){
        return request({
            url: urlConfig.systemUrl + '/wx/user/userBind',
            method: 'post',
            data: param
        })
    },
    /**
     * 获取验证码
     */
   getIdentifyingCode(registerKey,mobile){
        return request({
            url: urlConfig.systemUrl + '/user/generateRegisterCode?registerKey=' + registerKey + '&mobile=' + mobile,
            method: 'get',
            data: {}
        })
    },
    /**
     * 验证码登录
     */
    loginByMobile(registerKey,registerCode,mobile){
        return request({
            url: urlConfig.systemUrl + '/user/loginBymobile?registerKey=' + registerKey + '&registerCode=' + registerCode + '&mobile=' + mobile,
            method: 'post',
        })
    },
    /**
     * 密码重置
     */
    updatePassword(registerKey,registerCode,param){
        return request({
            url: urlConfig.systemUrl + '/user/updatePassword?registerKey=' + registerKey + '&registerCode=' + registerCode,
            method: 'put',
            data: param
        })
    }
}

export default loginService
