
let storage = {
    /**
     * 获取localStorage
     */
    get: (name) => {
        if (!name) return
        let value = window.localStorage.getItem(name)
        if (value !== null) {
            try {
                value = JSON.parse(value)
            } catch (e) {
                value = e
            }
        }
        return value;
    },

    /**
     * 存储localStorage
     */
    set: (name, content) => {
        if (!name) return
        if (typeof content !== 'string') {
            content = JSON.stringify(content)
        }
        window.localStorage.setItem(name, content)
    },

    /**
     * 删除localStorage
     */
    remove: (name) => {
        if (!name) return
        window.localStorage.removeItem(name)
    },

    /**
     ``* 存储accessToken
    */
    setToken: (token) => {
        if (!token) return;
        window.localStorage.setItem("accessToken", token);
    }
}

export default storage