/**
 * 网关或代理的前缀，测试本地服务之间修改成本地ip
 * 其他默认会走服务器上的nginx 代理
 * 一般不需要更改此文件
 * @type {string}
 */
const homeUrl = "/home";
const infoUrl = "/info";
const searchUrl = "/search";
const systemUrl = "/system";
const personUrl = "/person";
const wxappUrl = "http://zrzy.pro.gisquest.com:10108/gs/?id=";
//没有走request.js,无法自动拼装url,所以单独提出来
const upLoadUrl =process.env.VUE_APP_BASE_API+'/system';
export default {
    homeUrl,
    infoUrl,
    searchUrl,
    systemUrl,
    wxappUrl,
    upLoadUrl,
    personUrl,
}
