import request from '@/utils/request'
import urlConfig from "../utils/urlConfig";

/**
 * 首页轮播图
 * 完成
 */
export function getSwiperListByClass() {

    return request({
        url: urlConfig.homeUrl + '/home/getSwiperListByClass',
        method: 'POST',
        data: {}
    })
}

/**
 * 通过对象获取板块列表
 */
export function getPlateListByClass(data) {

    return request({
        url: urlConfig.homeUrl + '/home/getPlateListByClass',
        method: 'POST',
        data: data
    })
}


/**
 *  通过对象获取主题列表
 * @param Class：
 */
export function getThemeListByClass(Class) {

    return request({
        url: urlConfig.homeUrl + '/home/getThemeListByClass',
        method: 'POST',
        data: Class
    })
}

/**
 *  枚举分类获取
 * @param zudm：三类（djdylx、twlx、twlb）
 */
export function getEnumList(zudm) {

    return request({
        url: urlConfig.homeUrl + '/home/getEnumListByClass/',
        method: 'post',
        data: zudm
    })
}

export function getEnumListByXzq(zudm) {
    return request({
        url: urlConfig.homeUrl + '/home/getEnumListByXzq/',
        method: 'post',
        data: zudm
    })
}


/**
 *  搜索页枚举分类集合
 *  完成
 */
export function getSearchEnumList() {

    return request({
        url: urlConfig.homeUrl + '/home/getSearchEnumList',
        method: 'GET'
    })
}


/**
 * 获取枚举分类
 */
export function getEnumGroupList(zudm) {
    return request({
        url: urlConfig.homeUrl + '/home/getEnumGroupByZudms',
        method: 'POST',
        data: zudm
    })
}

/**
 * 获取首页菜单
 */
export function getAllTSyDhlDO() {

    return request({
        url: urlConfig.homeUrl + '/home/getAllTSyDhlDO',
        method: 'POST'
    })
}


/**
 * 通过对象获取图文信息
 */
export function getGraphInfoByClass(data) {
    return request({
        url: urlConfig.homeUrl + '/graph/getGraphInfoList',
        method: 'post',
        data: data
    })
}
/**
 * 通过对象获取图文信息
 */
export function getGraphInfoListBySy(type) {
    return request({
        url: urlConfig.homeUrl + '/graph/getGraphInfoListBySy/'+type,
        method: 'get'
    })
}

export function getGraphImagesByClass(tab) {
    return request({
        url: urlConfig.homeUrl + '/graph/getGraphImagesByClass',
        method: 'post',
        data: tab
    })
}

/**
 * 获取单元分类管理配置
 * @param zrzyId
 * @returns {AxiosPromise}
 */
export function getDyTab(zrzyId) {
    return request({
        url: urlConfig.homeUrl + '/dytab/getTabMkByZrzyId?zrzyId=' + zrzyId,
        method: 'get',
        data: {}
    })
}

/**
 * 根据条件查询tab( 分组)
 * @param tab
 */
export function getTabDataGroupByClass(tab) {
    return request({
        url: urlConfig.homeUrl + '/dytab/getTabGroupByClass',
        method: 'post',
        data: tab
    })
}

/**
 * 根据条件查询tab(不分组)
 * @param dy
 * @returns {AxiosPromise}
 */
export function gettabData(tabdy) {
    return request({
        url: urlConfig.homeUrl + '/dytab/getTabDataByClass',
        method: 'post',
        data: tabdy
    })
}

/**
 * 根据ID获取图文模板
 * @param id
 */
export function getGraphInfoById(id) {
    return request({
        url: urlConfig.homeUrl + '/graph/getGraphInfoById?mbId=' + id,
        method: 'GET'
    })
}

export function getThemesByPlateId(plateId) {
    return request({
        url: urlConfig.homeUrl + '/theme/getThemesByPlateId?plateId=' + plateId,
        method: 'GET',
    })
}


/**
 * 获取投诉建议信息
 * @param data
 */
export function getHomeSystem() {
    return request({
        url: urlConfig.homeUrl + '/home/getHomeSystem',
        method: 'get'
    })
}

export function getHomeStatisticsByZylx(param) {
    return request({
        url: urlConfig.homeUrl + '/home/homeStatisticsByZylx',
        method: 'post',
        data: param
    })
}

export function getHomeStatisticsByXzq(param) {
    return request({
        url: urlConfig.homeUrl + '/home/homeStatisticsByXzq',
        method: 'post',
        data: param
    })
}
