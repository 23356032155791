import axios from 'axios'
import {Message,MessageBox} from 'element-ui'
import storage from '@/utils/storage';
import router from "../router";
// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 1000000 // request timeout
})

service.interceptors.request.use(config => {
    const user = storage.get("user")
    if (user != null && user.access_token != null) {
        // let each request carry token
        // ['X-Token'] is a custom headers key
        // please modify it according to the actual situation

        config.headers['X-Gisq-Token'] = "Bearer " + user.access_token;
    }
    return config
}, error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
})

// response interceptor
service.interceptors.response.use(/**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */response => {
        const res = response.data
        // if the custom code is not 20000, it is judged as an error.
        if (res.code !== "0" && res.status != '200') {
            var msg = res.msg;
            if (msg == undefined) {
                return Promise.reject(new Error('Error'))
            } else if (msg != '') {
                if(msg.indexOf("不相等")!==-1){
                    msg = "验证码错误！"
                }else if(msg.indexOf("注册码不存在")!==-1){
                    msg = "验证码不存在！"
                }
                Message({
                    message: msg || 'Error', type: 'error', duration: 5 * 1000
                })
            }
            return Promise.reject(new Error(msg || 'Error'))
        } else {
            return res
        }
    }, error => {
        const response = error.response;
        if (response.status === 401) {
            MessageBox.confirm('系统未登录，是否登录?', '提示', {
                confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
            }).then(() => {
                storage.remove("user");
                router.push({
                    path: '/login'
                })
            }).catch(() => {
            });
        } else {
            console.log('err' + error) // for debug
            Message({
                message: error.response.data.message, type: 'error', duration: 5 * 1000
            })
            return Promise.reject(error)
        }
    })

export default service
