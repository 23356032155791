import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element'
import './assets/css/reset.css'
import store from "./store/store";
import VueStomp from "vue-stomp";
import Share from 'vue-social-share'
import 'vue-social-share/dist/client.css';


Vue.config.productionTip = false
Vue.use(VueStomp, process.env.VUE_APP_ENDPOINT);
Vue.use(Share);
new Vue({
    render: h => h(App),
    router,
    store,
}).$mount('#app')


