import Vue from 'vue'
import VueRouter from 'vue-router'
import header from '@/components/common/header'
import banner from '@/components/common/banner'
import storage from "../utils/storage";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/index'
    },
    {
        path: '*',
        redirect: '/404',
        hidden: true
    },
    {
        path: '/index',
        name: 'index',
        components: {
            header,
            banner,
            default: () => import('@/views/index')
        },
        meta: {
            title: '自然资源统一确权登记'
        }
    },
    {
        path: '/info',
        name: 'info',
        components: {
            header,
            default: () => import('@/views/info')
        },
        meta: {
            title: '登记成果'
        },
        children: [
            {
                path: 'travel',
                name: '地图公示',
                component: () => import('@/views/travel')
            }
        ]
    },
    {
        path: '/info/infoDetail',
        name: 'infoDetail',
        components: {
            header,
            default: () => import('@/views/info/detail')
        },
        meta: {
            title: '单元详情'
        }
    },
    {
        path: '/search/:key',
        name: 'search',
        components: {
            header,
            default: () => import('@/views/search')
        },
        meta: {
            title: '搜索结果'
        }
    },
    {
        path: '/dataUploadRanking',
        name: 'dataUploadRanking',
        components: {
            header,
            default: () => import('@/views/dataUploadRanking')
        },
        meta: {
            title: '数据上传排名情况'
        }
    },
    {
        path: '/news',
        name: 'news',
        components: {
            header,
			default: () => import('@/views/news')
        },
        meta: {
            title: '新闻报道'
        }
    },
    {
        path: '/news/newsDetail',
        name: 'newsDetail',
        components: {
            header,
            default: () => import('@/views/news/detail')
        },
        meta: {
            title: '详情'
        }
    },
    {
        path: '/policy',
        name: 'policy',
        components: {
            header,
            default: () =>import('@/views/policy')
        },
        meta: {
            title: '政策法规'
        }
    },
    {
        path: '/policyRead',
        name: 'policyRead',
        components: {
            header,
            default: () =>import('@/views/policy')
        },
        meta: {
            title: '政策解读'
        }
    },

    {
        path: '/policyRead/policyDetail',
        name: 'policyReadDetail',
        components: {
            header,
            default: () => import('@/views/news/detail')
        },
        meta: {
            title: '详情'
        }
    },
    {
        path: '/policy/policyDetail',
        name: 'policyDetail',
        components: {
            header,
            default: () => import('@/views/news/detail')
        },
        meta: {
            title: '详情'
        }
    },
    {
        path: '/support',
        name: 'support',
        components: {
            header,
			default: () => import('@/views/support')
        },
        meta: {
            title: '公众留言'
        }
    },
    {
        path: '/travel',
        name: 'travel',
        components: {
            header,
            default: () => import('@/views/travel')
        },
        meta: {
            title: '地图公示'
        }
    },
    {
        path: '/shot',
        name: 'shot',
        components: {
            header
        },
        meta: {
            title: '美景美拍'
        }
    },
    {
        path: '/tour',
        name: 'tour',
        components: {
            header
        },
        meta: {
            title: '虚拟旅游'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/login'),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/model-detail',
        name: 'model-detail',
        component: () => import('@/views/demo/detail'),
        meta: {
            title: '详情模版页面'
        }
    },
    {
        path: '/unit-model-detail',
        name: 'unit-model-detail',
        component: () => import('@/views/info/detail'),
        meta: {
            title: '单元模版页面'
        }
    },
    {
        path: '/signup',
        name: 'signup',
        components: {
            header,
            default: () => import('@/views/signup/signup'),
        },
        meta: {
            title: '注册'
        }
    },
    {
        path: '/retrievePassword',
        name: 'retrievePassword',
        components: {
            header,
            default: () => import('@/views/retrievePassword/retrievePassword'),
        },
        meta: {
            title: '找回密码'
        }
    },
    {
        path: '/vr',
        name: 'vr',
        component: () => import('@/components/vr.vue'),
        meta: {
            title: '全景图片'
        }
    },
	{
	    path: '/tsjycon',
	    name: 'tsjycon',
	    components: {
	        header,
			default: () => import('@/views/tsjycon')
	    },
	    meta: {
	        title: '公众留言'
	    }
	},
    {
        path: '/notice',
        name: 'notice',
        components: {
            header,
            default: () => import('@/views/notice')
        },
        meta: {
            title: '通告公告'
        }
    },
    {
        path: '/localExperience',
        name: 'localExperience',
        components: {
            header,
            default: () => import('@/views/localExperience')
        },
        meta: {
            title: '地方经验'
        }
    },
    {
        path: '/localExperience/localExperienceDetail',
        name: 'localExperienceDetail',
        components: {
            header,
            default: () => import('@/views/news/detail')
        },
        meta: {
            title: '地方经验详情'
        }
    },
    {
        path: '/notice/noticeDetail',
        name: 'noticeDetail',
        components: {
            header,
            default: () => import('@/views/news/detail')
        },
        meta: {
            title: '详情'
        }
    },
	{
	    path: '/info/infoDetail/zygkInfor',
	    name: 'zygkInfor',
	    components: {
	        header,
			default: () => import('@/views/kpzsInfor/zygkInfor')
	    },
	    meta: {
	        title: '资源概况'
	    }
	},
    {
        path: '/info/infoDetail/customPage',
        name: 'zygkInfor',
        components: {
            header,
            default: () => import('@/views/info/detail/components/customPage')
        },
        meta: {
            title: '外部链接'
        }
    },

	{
        path: '/info/infoDetail/zrhjInfor',
        name: 'zrhjInfor',
        components: {
            header,
            default: () => import('@/views/kpzsInfor/zrhjInfor')
        },
        meta: {
            title: '自然环境'
        }
    },

    {
        path: '/info/infoDetail/lsrwInfor',
        name: 'lsrwInfor',
        components: {
            header,
            default: () => import('@/views/kpzsInfor/lsrwInfor')
        },
        meta: {
            title: '历史人文列表'
        }
    },
	{
	    path: '/info/infoDetail/lsrwInfor/lsrwDetail',
	    name: 'lsrwInforDetail',
	    components: {
	        header,
			default: () => import('@/views/kpzsInfor/detail/lsrwDetail')
	    },
	    meta: {
	        title: '历史人文详情'
	    }
	},
	{
	    path: '/info/infoDetail/zrhjInfor/zrhjDetail',
	    name: 'zrhjDetail',
	    components: {
	        header,
			default: () => import('@/views/kpzsInfor/detail/zrhjDetail')
	    },
	    meta: {
	        title: '自然环境详情'
	    }
	},
    {
        path: '/info/infoDetail/sttyInfor',
        name: 'sttyInfor',
        components: {
            header,
            default: () => import('@/views/kpzsInfor/sttyInfor')
        },
        meta: {
            title: '生态列表'
        }
    },
	{
	    path: '/info/infoDetail/sttyInfor/sttyDetail',
	    name: 'sttyInforDetail',
	    components: {
	        header,
			default: () => import('@/views/kpzsInfor/detail/sttyDetail')
	    },
	    meta: {
	        title: '生态体验详情'
	    }
	},
    {
        path: '/info/infoDetail/lvtyInfor',
        name: 'lvtyInfor',
        components: {
            header,
            default: () => import('@/views/kpzsInfor/lvtyInfor')
        },
        meta: {
            title: '旅游列表'
        }
    },
    {
        path: '/info/infoDetail/lvtyInfor/lvtyDetail',
        name: 'lvtyInforDetail',
        components: {
            header,
            default: () => import('@/views/kpzsInfor/detail/lvtyDetail')
        },
        meta: {
            title: '人文历史'
        }
    },
    {
        path: '/profile',
        name: 'profile',
        components: {
            header,
            default: () => import('@/views/profile')
        },
        redirect: '/profile/personal',
        meta: {
            title: '个人中心'
        },
        children: [
            {
                path: 'personal',
                name: '个人信息',
                component: () => import('@/views/profile/personal')
            },
            {
                path: 'follow',
                name: '我的关注',
                component: () => import('@/views/profile/follow')
            },
            {
                path: 'collection',
                name: '我的收藏',
                component: () => import('@/views/profile/collection')
            },
            {
                path: 'tsjyList',
                name: '投诉建议',
                component: () => import('@/views/profile/tsjyList')
            },
            {
                path: 'pwdset',
                name: '修改密码',
                component: () => import('@/views/profile/pwdset')
            }
        ]
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
    // 根据路由信息，设置页面title
    if(to.meta.title){
        document.title = to.meta.title
    }
    if (storage.get("user") != null && to.path === '/login') { // 判断是否有token
        next({path: '/'});
    } else {
        next()
    }
    window.scrollTo(0,0)
})

export default router
