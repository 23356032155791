import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const state = {
    enumMap: new Map(),
    userInfo: {},
    homeSystem: {},
    tsWckl: [],
};

export default new Vuex.Store({
    state,
    mutations: {
        addUser(state, param) {
            state.userInfo = param.user;
        },
        addHomeSystem(state, param) {
            state.homeSystem = param;
        },
        updateTsWckl(state, param) {
            state.tsWckl = param
        }
    },
    actions: {},
    getters: {}
})
