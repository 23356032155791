import request from '@/utils/request'
import urlConfig from "../utils/urlConfig";

/**
 *  按分类获取最新的单元信息
 * @param type（最新鲜、最热门、国家公园、自然公园、自然保护区）
 */
export function getDyInfo(type) {

    return request({
        url: urlConfig.infoUrl + '/info/newDyInfo/' + type,
        type: 'GET',
        data: {}
    })
}

/**
 *  通过登记单元号获取该单元的信息
 * @param id（登记单元号）
 */
export function getDyOneInfoByDyId(id, djdyh) {

    return request({
        url: urlConfig.infoUrl + '/info/getDyOneInfoByDyId?id=' + id + '&djdyh=' + djdyh,
        type: 'GET',
        data: {}
    })
}

/**
 *  通过登记单元号获取该单元的详情信息
 * @param id（登记单元号）
 */
export function getDyDetailsInfo(id) {

    return request({
        url: urlConfig.infoUrl + '/info/getDyDetailsInfo/' + id,
        type: 'GET',
        data: {}
    })
}

/**
 * 条件搜索单元详情
 * @param search
 * @returns {Promise<unknown>}
 */
export function getDyInfoBySearch(search) {
    return request({
        url: urlConfig.infoUrl + '/info/queryDyInfo',
        method: 'post',
        data: search
    })
}

export function getDyInfoBytabId(id) {
    return request({
        url: urlConfig.infoUrl + '/info/getDyInfoByTabId?tabId=' + id,
        method: 'get'
    })
}

/**
 * 获取全部单元信息，天地图使用
 */
export function getAllDyInfo() {
    return request({
        url: urlConfig.infoUrl + '/info/getAllDyInfo',
        method: 'GET'
    })
}

/**
 * 根据登记单元名称准确查询
 * @param djdymc 登记单元名称
 */
export function selectByDJDYMC(djdymc) {
    return request({
        url: urlConfig.infoUrl + '/info/selectByDJDYMC?djdymc=' + djdymc,
        method: 'GET'
    })
}
